.hours-list-wrapper {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid rgb(202, 202, 202);

  .calendar-hours-title {
    text-transform: capitalize;
  }

  .hour-list-item {
    display: block;
    min-height: 2em;
    min-width: 100%;
    border-bottom: 1px solid rgb(202, 202, 202);
    position: relative;

    &:not(.disabled):hover {
      background-color: #e3fff9;
      cursor: pointer;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &.disabled {
      background-color: rgb(235, 235, 235);
    }

    &.half-disabled {
      background: linear-gradient(180deg, #ffffff 50%, rgb(235, 235, 235) 50%);
    }

    & > label {
      transform: translateY(50%);
    }
  }
}

.calendar-hours-wrapper {
  display: flex;
}

.calendar-hours-title {
  font-weight: bold;
  color: #1c5e58;
  font-size: 0.8em;
  padding: 10px;
  background-color: #e3fff9;
  border: 1px solid rgb(202, 202, 202);
}

@media screen and (max-width: 500px) {
  .calendar-hours-wrapper {
    width: 100%;
    align-items: flex-start;
    .hours-list-wrapper {
      flex-grow: 1;
      .hour-list-item {
        min-width: 100%;
      }
    }
  }
}
