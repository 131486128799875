.calendar-page-wrapper {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
}

.view-cal-item-modal {
  .modal-content {
    min-height: 220px;
    display: flex;
    flex-direction: column;
  }
}
