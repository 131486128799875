.cal-item-view-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .cal-item-date-status {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .date-label {
      color: #ffa445;
      font-size: 1.1em;
      font-weight: bold;
    }
  }

  .cal-item-view-details {
    > div {
      margin-top: 10px;
    }
    .cal-item-notifications {
      font-size: 0.8em;
    }
    .cal-item-metadata {
      margin: 10px 0;
      padding: 20px 10px;
      text-align: start;
      border: 1px solid lightgray;
      font-size: 0.8em;
    }
  }

  .cal-item-view-actions-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
