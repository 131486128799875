.user-menu {
  max-width: 50px;
  cursor: pointer;

  .user-menu-icon {
    width: 45px;

    * {
      stroke: none;
      fill: var(--colorPrimary);
    }
  }

  .icon-btn {
    border: none;
    background: none;
    width: fit-content;
    cursor: pointer;
    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  .hamburger-button {
    display: none;
    position: relative;
    width: 22px;
    height: 22px;
    border: 2px solid var(--colorPrimary);
    border-left: none;
    border-right: none;
    background: none;
    &:before {
      position: absolute;
      left: 0;
      top: 8px;
      width: 100%;
      height: 2px;
      background: var(--colorPrimary);
      content: '';
    }

    @media screen and (max-width: 500px) {
      display: block;
    }
  }
}
