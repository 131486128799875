.report-page-wrapper {
  .report-page-date-range-controls {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;

    [class*="-report-date"] {
      margin: 0 1em;
    }
  }

  .report-page-content {
    max-width: 1440px;
    padding: 20px;
    margin: 0 auto;
    display: block;

    .report-totals-for-period {
      margin-top: 2em;
    }

    .report-breakdown-controls {
      display: block;
      text-align: left;
    }
  }
}

@media only screen and (max-width: 500px) {
  .report-page-wrapper {
    .report-page-date-range-controls {
      flex-direction: column;
    }
  }
}
