.working-time-component {
  display: flex;
  flex-direction: column;
  grid-column: 1/4;
  .working-time-component-working-days {
    .working-time-component-days-list {
      list-style: none;
      padding: 0;
      margin: 0;
      > li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0;

        span {
          min-width: 2.4em;
        }

        div.disabled {
          input {
            background-color: #f0f0f0;
            font-style: italic;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .working-time-component {
    .working-time-component-working-days {
      .working-time-component-days-list {
        > li {
          font-size: 0.8rem;
        }
      }
    }
  }
}