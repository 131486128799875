.login-page-wrapper {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.login-wrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  max-width: 600px;
  margin: auto;
  flex-wrap: wrap;
  border: 1px solid lightgray;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;

  > div {
    flex: 1 0 50%;
    display: block;
    min-height: 100px;
    min-width: 250px;
    width: calc(100% * (1 / 2) - 10px - 1px);
  }

  .language-picker {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: fit-content;
    min-width: fit-content;
    height: fit-content;
    min-height: fit-content;
  }

  .login-logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    > img {
      width: 100%;
      height: 100%;
    }
  }

  .login-form-wrapper {
    form {
      padding: 12px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .form-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      div {
        margin: 5px auto;
      }

      .login-providers {
        align-self: center;
      }
    }

    span.error-box {
      align-self: center;
      font-size: 0.6rem;
      color: red;
    }
  }
}

@media screen and (max-width: 500px) {
  .login-wrapper {
    border: none;
    box-shadow: none;
    .login-logo-wrapper {
      > img {
        width: 70%;
        height: 70%;
      }
    }

    .language-picker {
      position: fixed;
      top: 10px;
      right: 0px;
      left: initial;
    }
  }
}

@media screen and (max-width: 850px) {
  .login-wrapper {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 768px) {
  .login-wrapper {
    font-size: 0.8em;
    border: none;
    .login-logo-wrapper {
      > img {
        width: 70%;
        height: 70%;
      }
    }
  }
}
