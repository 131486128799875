body,
html,
h1 {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.App {
  flex: 1 0 auto;
  text-align: center;

  --colorSecondary: #ffa445;
  --colorPrimary: #0fd8d2;
  --colorTableHeader: #e3fff9;
  --colorTableHeaderText: #1c5e58;
}
.static-page-container {
  margin: 0 auto;
  padding: 20px 0;
  width: 90%;
  max-width: min(90%, 800px);
  text-align: justify;
  overflow-y: auto;
}
.static-page-container.logged-in {
  padding: 0 0 20px;
  overflow-y: auto;
}
.shadow {
  box-shadow: 0 0 14px 3px #00000052;
}

@media screen and (min-width: 501px) {
  .mobile-only {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .not-mobile {
    display: none;
  }
}
