.tableWrap {
  height: 70vh;

  .table-header {
    background-color: var(--colorPrimary);
  }

  .table-cell {
    box-sizing: border-box;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    // border-bottom-color: var(--colorSecondary);
    padding-bottom: 5px;
    margin-bottom: 5px;
    height: 48px;
  }

  @media screen and (max-width: 768px) {
    thead th:not(.fixed),
    tbody td:not(.fixed) {
      display: none;
    }
  }

  .hidden {
    display: none;
  }

  .pagination {
    grid-area: pagination;
  }

  .pagination span {
    font-size: 1.5rem;
    vertical-align: middle;
  }

  .table-footer {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-areas: 'filter pagination .';
  }

  .users-table-filter {
    position: relative;
    height: 2.5em;
    width: 80%;
    margin: 10px auto;
    justify-self: flex-start;
    border: 2px solid var(--colorSecondary);
    border-radius: 5px;
    justify-self: stretch;
    cursor: text;
    padding: 2px;
    display: flex;
    &:focus-within {
      border-color: var(--colorPrimary);
    }

    input {
      border: none;
      height: 100%;
      float: left;
      outline: none;
    }
  }

  .users-table-filter button {
    position: absolute;
    right: 0;
    width: 20px;
    height: 100%;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    opacity: 0.3;
    transition: opacity 0.2s ease-in;
    font-weight: 700;

    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    .table-footer {
      grid-template-rows: 50px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas: 'filter' 'pagination';
    }
    .users-table-filter {
      width: 97%;
    }

    .user-pending:first-of-type {
      border-left: 5px solid rgb(224, 211, 19);
    }

    .user-disabled:first-of-type {
      border-left: 5px solid #d03400;
    }
  }
}
