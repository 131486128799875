.hours-labels-list-wrapper {
  list-style: none;
  padding: 0 5px;
  margin: 0;

  .hour-labels-list-label {
    display: flex;
    align-content: end;
    align-items: end;
    justify-content: center;
    min-height: 2em;
    & > label {
      transform: translateY(50%);
      color: #1c5e58;
      font-size: 0.8em;
      font-style: italic;
    }

    &:first-of-type {
      padding: 10px;
      color: transparent;
    }
  }
}
