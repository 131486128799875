.toast-notifications {
  position: absolute;
  z-index: 1000000;
  margin: 0;
  padding: 0;
  //   width: max-content;
  width: 500px;

  @media screen and (max-width: 768px) {
    width: 90vw;
  }

  --toastMargin: 30px;
  --trX: -50%;
  --trY: -50%;

  left: 50%;
  top: 50%;
  transform: translate(var(--trX), (var(--trY)));

  &.left {
    left: var(--toastMargin);
    --trX: 0;
  }
  &.top {
    top: var(--toastMargin);
    --trY: 0;
  }
  &.bottom {
    top: unset;
    bottom: var(--toastMargin);
    --trY: 0;
  }
  &.right {
    left: unset;
    right: var(--toastMargin);
    --trX: 0;
  }

  .toast {
    list-style: none;
    font-size: 1.2rem;
    padding: 0.5em 1em;
    background-color: rgb(99, 99, 99);
    color: white;
    margin: 2px;
    border-radius: 4px;
    cursor: pointer;

    &.positive {
      background-color: rgb(21, 82, 21);
    }

    &.negative {
      background-color: rgb(165, 19, 19);
    }

    animation: fadeIn 300ms ease-in forwards, fadeOut 2s ease-out 2s forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
