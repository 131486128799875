.header {
  position: relative;
  min-height: 1.5rem;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin: 0 0 16px;

  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: var(--colorPrimary);
    box-shadow: 0 0 6px #888;
    content: '';
  }

  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    margin: 0 auto;
    & > div {
      display: flex;
      gap: 30px;
      justify-content: space-between;
      align-items: center;
      max-width: calc(100% - 132px);
    }
    h1 {
      font-weight: normal;
      font-size: 1.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .logo-link {
    height: 100%;
    svg {
      max-width: 128px;
      height: 100%;
    }
    &:hover {
      svg {
        filter: drop-shadow(0 3px 3px #888);
      }
    }
  }

  .icon-btn {
    border: none;
    background: none;
    cursor: pointer;
    min-width: 80px;
    width: 80px;
    padding: 0;
    svg {
      width: 42px;
      height: 42px;
      filter: drop-shadow(0px 3px 3px #bbb);
      stroke: none;
    }
    span {
      display: inline-block;
      width: 100%;
    }
    &:hover {
      svg {
        filter: drop-shadow(0px 3px 3px #888);
      }
      span {
        font-weight: bold;
      }
    }
    &.profile svg {
      fill: var(--colorPrimary);
    }
    &.logout svg {
      fill: var(--colorSecondary);
    }
  }
}

// .profile-dropdown-menu {
//   position: absolute;
//   left: auto;
//   right: 145px;
//   top: 100px;
//   width: auto;
//   min-height: 0;
//   transform: none;
//   border: 1px solid var(--colorPrimary);
//   border-bottom: none;
//   .modal-content {
//     padding: 0;
//   }
// }
// .menu-items {
//   width: 200px;
//   button {
//     width: 100%;
//     height: 50px;
//     border: none;
//     background: none;
//     float: left;
//     clear: both;
//     border-bottom: 1px solid var(--colorPrimary);
//     cursor: pointer;
//     &:hover {
//       font-weight: bold;
//       background: #bfe7e6;
//     }
//   }
// }

@media screen and (max-width: 500px) {
  .header {
    .header-inner {
      height: 50px;
      // h1 {
      //   display: none;
      // }
      .logo-link {
        svg {
          max-width: 64px;
        }
      }
    }
  }
  // .menu-items {
  //   width: 100%;
  // }
}
