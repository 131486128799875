.slide-in-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.2);
  z-index: 10;
  display: none;
  animation: fadeOut 0.5s linear forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  &.show {
    animation: fadeIn 0.5s linear forwards;
  }
}

.slide-in-container {
  position: fixed;
  display: block;
  right: 0;
  top: 0;
  background-color: white;
  width: 1px;
  height: 100%;
  z-index: 10;
  transition-property: transform, opacity;
  transition-duration: 250ms;
  transition-delay: 250ms;
  transform: translateX(100%);
  width: 50vw;

  &.show {
    transform: none;
  }

  .slide-in-content {
    overflow: auto;
    height: 100%;

    position: relative;
    padding: 10px;
    padding-top: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .slide-in-container {
    width: 70%;
  }
}

@media only screen and (max-width: 500px) {
  .slide-in-container {
    width: 100%;
    padding: 0;
  }
}
