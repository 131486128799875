.spinner-container {
  //Edit this value to change the size of the spinner
  --spinner-scale: 1.3;
  //----------------------------------------------------

  --img-size: calc(100px * var(--spinner-scale));
  --text-width: calc(98px * var(--spinner-scale));
  --text-height: calc(55px * var(--spinner-scale));

  height: calc(100px * var(--spinner-scale));
  width: calc(100px * var(--spinner-scale));
  margin: 0 auto;

  .spinner-text {
    background: url(../img/meda-text.svg);
    background-size: var(--text-width) var(--text-height);
    background-position: center;
    background-repeat: no-repeat;
    width: var(--text-width);
    height: var(--text-height);
    margin-top: calc(-70px * var(--spinner-scale));
  }

  .spinner-logo {
    //Made both orange and green dragonfly. We'll decide which one to use later.
    background: url(../img/dragonfly-orange.svg);
    //   background: url(../img/logo-green.svg);
    background-position: center;
    background-size: var(--img-size) var(--img-size);
    height: var(--img-size);
    width: var(--img-size);

    animation: spin 2.5s normal infinite linear;
  }

  /* Overlay styles */
  &.as-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.747);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
