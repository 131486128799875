.confirmation-dialog {
  z-index: 300;

  .confirmation-content {
    margin: 10px 5px 20px 5px;
  }

  .confirmation-buttons {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
  }

  .confirm-modal {
    width: unset;
    min-width: 400px;
    max-width: 30%;
    min-height: 120px;
    border: none;
    box-shadow: 0 0 15px 2px #00000040;

    .modal-content {
      padding: 5px 15px;
      padding-top: 20px;
    }

    h1 {
      margin-bottom: 1em;
    }

    button {
      min-width: 5rem;
      height: 2.2rem;
      margin: 0 15px 0 15px;
    }

    @media screen and (max-width: 768px) {
      min-width: 90%;
    }
  }
}
