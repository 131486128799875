.global-footer {
  height: 72px;
  position: relative;
  background: #f2f2f2;
  border-top: 1px solid var(--colorSecondary);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  margin: auto 0 0;
  font-size: 0.875rem;
  text-align: center;
  color: #70757a;
  line-height: 24px;

  a,
  button {
    background: none;
    border: none;
    margin: 0 10px;
    padding: 0;
    font-size: 0.875rem;
    color: #70757a;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  button:not(.visible) {
    display: none;
  }
  span {
    width: 100%;
  }

  /* Mobile styles */
  @media screen and (max-width: 767px) {
    font-size: 1rem;
    height: 80px;
    line-height: 28px;

    a,
    button {
      font-size: 1rem;
      height: 28px;
    }
    .links-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: absolute;
      left: 0;
      top: -1px;
      width: 100%;
      height: 0;
      background: #f2f2f2;
      border-top: 1px solid orange;
      overflow: hidden;
      transition: height 0.3s linear, top 0.3s linear;
    }
    button:not(.visible) {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding-right: 16px;
      position: relative;
      &:after {
        content: '\25b2';
        position: absolute;
        right: 0;
        top: 3px;
        transition: transform 0.3s linear, top 0.3s linear;
      }
    }

    &.visible-links {
      .links-wrapper {
        top: -30px;
        height: 40px;
      }
      button:after {
        transform: rotate(180deg);
        top: 6px;
      }
    }
  }
}
