.change-password-form-wrapper {
  max-width: 300px;
  margin: 0 auto;
  form {
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      margin: 5px auto;
    }

    .google-login {
      align-self: center;
    }
  }

  span.error-box {
    align-self: center;
    font-size: 0.6rem;
    color: red;
  }
}
