.custom-fields-form {
  form {
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  p {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  p.err {
    color: red;
    display: none;
    text-align: center;
    margin-top: -6px;
    font-size: 0.8rem;
  }

  label:not(.lang-flag) {
    display: inline-block;
    width: 50%;
    padding: 6px;
    font-weight: bold;
    text-transform: capitalize;
    text-align: right;
  }

  input {
    max-width: 44%;
  }

  [type="submit"] {
    margin-left: 50%;
  }
}
