.calendar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 20px;
  max-width: 1080px;
  .range-title {
    text-transform: capitalize;
    &.day-picker-link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .calendar-controls {
    width: 100%;
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    align-items: center;
    h1 {
      display: inline-block;
      margin-left: 20px;
    }
    .calendar-controls-trainer-selector {
      flex-grow: 1;
      margin-left: auto;
      text-align: right;
    }
    .calendar-controls-day {
      display: none;
    }
  }

  .calendar-days-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    .calendar-day-wrapper {
      flex-grow: 1;
      &:not(:first-of-type) {
        .hours-labels-list-wrapper {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .calendar-wrapper .calendar-days-wrapper {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .calendar-wrapper .calendar-days-wrapper {
    font-size: 10px;
  }
}

@media screen and (max-width: 500px) {
  .calendar-wrapper {
    width: 100%;
    .calendar-controls {
      justify-content: center;
      .calendar-controls-week {
        display: none;
      }
      .calendar-controls-day {
        display: inline-block;
      }
      .calendar-controls-trainer-selector {
        flex-grow: unset;
        margin-left: unset;
        text-align: unset;
        max-width: fit-content;
      }
    }
    .calendar-days-wrapper {
      width: 100%;
      .calendar-day-wrapper {
        font-size: 16px;
        width: 100%;
        padding: 0 10px;
        .hours-labels-list-wrapper {
          display: block !important;
        }
        &:not(.current) {
          display: none;
        }
      }
    }
  }
}
