.modal-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 222;
  background-color: rgba(0,0,0,.2);
}

@keyframes showModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hideModal {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.modal {
  width: 640px;
  min-height: 220px;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid grey;
  border-radius: 6px;
  box-shadow: 0 0 7px 0px #CCC;
  z-index: 222;
  animation: showModal .2s linear;
  animation-duration-count: 1;
  &.hide-modal {
    animation: hideModal .2s linear;
    opacity: 0;
  }
  h2 {
    font-weight: normal;
    margin: 0;
    padding: 20px 0;
    border-bottom: 1px solid #BBB;
  }
  .modal-content {
    padding: 12px;
    overflow-y: auto;
  }
  .close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -10px;
    background: white;
    border: 1px solid gray;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    color: gray;
  }
}

