.cal-item-wrapper {
  display: flex;
  min-width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  z-index: 1;
  white-space: pre-wrap;
  overflow: hidden;

  &.active {
    background-color: #aac7eb;
    &.mine {
      background-color: #f59745;
    }
  }

  &.pending {
    background-color: #ffffc7;
    &.mine {
      background-color: #f8e267;
    }
  }
  &.disabled {
    background-color: red;
  }
}
