.lang-flag {
  margin-right: 20px;
  width: 32px;
  height: 22px;
  background: url("../../img/flags.png") no-repeat;
  background-size: 30px;
  border: 1px solid grey;
  cursor: pointer;
  display: inline-block;
  text-indent: -100px;
  overflow: hidden;
  opacity: 0.6;
}
.lang-flag.bg {
  background-position: 0 -20px;
}
input[type="radio"]:checked + .lang-flag {
  opacity: 1;
}
[type="radio"] {
  display: none;
}
