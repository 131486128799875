.create-event-status {
  position: absolute;
  font-size: 0.7rem;
  right: 1em;
  top: 1em;
  width: fit-content;

  @media only screen and (max-width: 1024px) {
    position: initial;
  }
}

.create-event-container {
  width: 90%;
  max-width: 550px;
  margin: 40px auto;
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  grid-template-rows: auto repeat(5, 1fr) 10fr;
  row-gap: 20px;

  .create-event-for-user {
    grid-row: 1 / 2;
    grid-column: 1 / 4;

    & > div {
      width: 100%;
    }
  }

  .create-event-date {
    grid-row: 2 / 3;
    grid-column: 1 / 4;
    text-align: center;
    font-weight: 600;

    & > div {
      width: 100%;
      input {
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }

  & > span {
    grid-row: 3 / 4;
    grid-column: 2 /3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.4em;
  }

  .create-event-start,
  .create-event-end {
    grid-row: 3 / 4;
    // width: 100%;
    & > div:first-of-type {
      width: 100%;
    }
  }
  .create-event-start {
    position: relative;
    grid-column: 1 / 2;
  }
  .create-event-end {
    grid-column: 3 / 4;
  }

  .create-event-duration {
    grid-row: 4 / 5;
    grid-column: 1 / 4;

    & > div {
      width: 100%;
    }
  }

  .create-event-notif-chb {
    grid-row: 5 / 6;
    grid-column: 1 / 4;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
  }

  .create-event-price-chb {
    grid-row: 6 / 7;
    grid-column: 1 / 4;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
  }

  .create-event-footer {
    grid-row: 7 / 8;
    grid-column: 1 / 4;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;

    & > span {
      color: red;
    }

    .create-event-onetime-notif{
      & > span {
        margin-left: 1em;
      }
    }
  }

  .create-event-end {
    opacity: 0.7;
  }

  .hasError {
    border: 2px solid red;
  }
}

@media only screen and (max-width: 768px) {
  .create-event-container {
    grid-template-rows: auto repeat(5, 1fr) 2fr;
  }
}
