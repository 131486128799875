.appointments-wrapper {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  padding: 0 20px 20px;
  max-height: calc(100vh - 264px); /* This will be valid max-height for phones */
  overflow: auto;
}
.appointments-header {
  display: none;
}

/* The following is to handle the change of height in the header */
@media screen and (min-width: 501px) {
  .appointments-wrapper {
    max-height: calc(100vh - 310px);
  }
}

@media screen and (min-width: 1020px) {
  .desktop-grid {
    display: flex;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }
  .appointments-wrapper {
    flex-direction: column;
    width: 1000px;
    max-height: calc(100vh - 340px);
    overflow-x: hidden;
    padding: 0 0 20px;
    grid-gap: 0;
  }
  .appointments-header {
    align-items: stretch;
    height: 36px;
    width: 1000px;
    background: #e5e5e5;
    margin-top: 10px;
    div {
      line-height: 36px;
      width: 200px;
      border-left: 1px solid #ffad5b;
      &:nth-child(3) {
        border-right: 1px solid #ffad5b;
      }
      &:last-child {
        width: 400px;
        border-left: none;
        border-right: 1px solid #ffad5b;
      }
    }
  }
}
