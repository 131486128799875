.reporting-totals-wrapper {
  > h1 {
    text-align: left;
  }

  .reporting-totals-container {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    flex-wrap: wrap;

    .record-row-caption {
      width: 20%;
      align-self: flex-end;
      font-weight: bold;
      padding: 5px 10px;
      font-size: 0.9em;
      border: 1px solid lightgray;

      &.even {
        background-color: rgb(235, 235, 235);
      }

      &.wrapped {
        &.even {
          background-color: rgb(210, 255, 255);
        }
        &.odd {
          background-color: #fdecd7;
        }
      }
    }

    .record-data-row {
      display: flex;
      flex-wrap: wrap;
      .record-item-view {
        min-width: 140px;
        max-width: 140px;
        font-size: 0.9em;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .record-header-cell {
          display: none;
          padding: 5px 10px;
          border: 1px solid lightgray;
          background-color: rgb(210, 255, 255);
          &.odd {
            background-color: #fdecd7;
          }
          &.first {
            display: block;
            flex-grow: 1;
          }
          &.show {
            display: block;
            flex-grow: 1;
          }
        }
        .record-data-cell {
          padding: 5px 10px;
          border: 1px solid lightgray;

          &.even {
            background-color: rgb(235, 235, 235);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .reporting-totals-wrapper .reporting-totals-container .record-row-caption {
    width: 50%;
  }
}

@media only screen and (max-width: 500px) {
  .reporting-totals-wrapper .reporting-totals-container .record-row-caption {
    width: 100%;
  }
}
