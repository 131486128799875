.one-appointment {
  padding: 20px 0 0;
  text-align: left;
  .title-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &#next-appointment {
    .title-line {
      font-weight: bold;
      color: var(--colorPrimary);
    }
    .start-and-end-times {
      background: #f2f2f2;
    }
  }
  .main-section {
    border: 1px solid #ffa041;
    .time-and-status {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 1019px) {
  .one-appointment {
    margin-bottom: -20px;
    &#next-appointment {
      .main-section {
        background: #f2f2f2;
      }
    }
    .main-section {
      margin-top: 8px;
      padding: 8px;
      .time-and-status {
        display: flex;
        align-items: center;
        b + b:before {
          content: '-';
          margin: 0 8px;
        }
        .status span {
          min-width: 1em;
          white-space: nowrap;
          padding: 3px 10px;
        }
      }
      .appointment-actions {
        button {
          border: none;
          span {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1020px) {
  .one-appointment {
    width: 1000px;
    div {
      display: flex;
    }
    .title-line {
      font-size: 1.25rem;
    }
    .main-section {
      position: relative;
      height: 40px;
      margin-top: 24px;
      border: none;
      .time-and-status {
        width: 60%;
        b,
        .status span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .start-and-end-times {
          width: 400px;
          b {
            flex: 1 0 auto;
            font-weight: normal;
            text-align: center;
            border-left: 1px solid #ffae5c;
          }
        }
        .status {
          width: 200px;
          span {
            flex: 1 0 auto;
            margin: 0;
            border: 1px solid #ffae5c;
            border-top: none;
            border-bottom: none;
          }
        }
      }
      .appointment-actions {
        position: absolute;
        left: 60%;
        top: 0;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 40%;
        button {
          width: 40%;
          margin: 0 0 0 7%;
        }
      }
    }
  }
}
