.pwa-banner {
  position: fixed;
  padding: 10px 2px;
  height: 50px;
  background: #000000d4;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: fit-content;
  width: 100%;
  bottom: 0;

  span {
    font-size: 1.5rem;
  }

  button {
    margin-left: 15px;
    white-space: nowrap;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    span {
      font-size: 1rem;
    }
  }
}
