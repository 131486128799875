.user-profile-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  .user-profile-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .avatar-container {
      margin-bottom: 10px;
      margin-right: 10px;
      .avatar-image {
        border: 1px solid var(--colorSecondary);
        margin: 2px;
        width: 10em;
        height: 10em;
        background-image: url('../../img/profile2.svg');
      }
    }
    .info-container {
      min-width: 70%;
      flex: 0.9;
      h1 {
        text-align: left;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          &:first-of-type {
            border-top: 1px solid var(--colorPrimary);
          }
          padding: 10px 0;
          text-align: left;
          border-bottom: 1px solid var(--colorPrimary);
        }
      }

      .user-profile-metadata {
        margin: 10px 0;
        padding: 20px 10px;
        text-align: start;
        border: 1px solid lightgray;
        font-size: 0.8em;
      }

      // height: 10em;
      // border: 1px solid blue;
    }
  }
}
