.admin-panel {
  position: relative;
}

.user-list {
  list-style: none;
  width: 200px;
  height: 150px;
  overflow-y: scroll;
  border: 1px solid gray;
  border-radius: 3px;
  text-align: left;
  margin-left: 100px;
  padding: 2px;
}

.user-list li {
  width: 100%;
  cursor: pointer;
}
.user-list li:hover {
  background: rgb(81, 81, 177);
  color: white;
}

.selected-user {
  list-style: none;
  text-align: left;
}

.selected-user em {
  font-style: normal;
  font-weight: 700;
}

.new-user-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: var(--colorPrimary);
  color: white;
  font-weight: 500;
  font-size: 3rem;
  vertical-align: middle;
  text-align: center;

  --text-outline: #aaa;
  text-shadow: -1px -1px 0px var(--text-outline), 1px 1px 0px var(--text-outline), 1px -1px 0px var(--text-outline),
    -1px 1px 0px var(--text-outline);
  border: none;
  border-radius: 100%;
  width: 1em;
  height: 1em;
  padding: 0;
  margin: 0;
  line-height: 1em;
  box-shadow: 0 0 6px 2px #00000036;
  transition: box-shadow 0.2s ease-in;
  cursor: pointer;
}

.new-user-button:hover {
  box-shadow: 0 0 6px 4px #00000036;
}
