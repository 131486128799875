.create-user-container {
  width: 90%;
  max-width: 550px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  min-height: 80%;

  > div {
    &:last-of-type {
      margin-top: auto;
    }
    &.tab {
      max-width: 550px;
      margin: 40px auto;
      display: grid;
      row-gap: 20px;
      grid-column: 1/4;
      grid-template-columns: 3fr 1fr 3fr;
    }
  }

  .create-user-controls {
    grid-row: 1/2;
    grid-column: 1/4;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;

      > li {
        padding: 10px;
        min-width: 150px;
        border: 1px solid lightgray;
        cursor: pointer;

        &.selected {
          background-color: lightgrey;
        }
      }
    }
  }

  .create-user-text-box {
    grid-column: 1 / 4;
  }

  .create-user-roles-list {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;
    > li {
      label {
        margin-right: 1em;
      }
    }
  }

  .create-user-footer {
    width: 100%;
    display: flex;
    flex-direction: column;

    & > small {
      color: red;
    }
  }

  .trainer-dropdown {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .create-user-container {
    grid-template-rows: auto repeat(1, 1fr) 1fr;
  }
}
